import React, { Component, useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom'
import SidebarUser from '../../layouts/SidebarUser'
import Header from '../../layouts/Header'
import truncateEthAddress from 'truncate-eth-address'
import Footer from '../../layouts/Footer';
import 'react-responsive-modal/styles.css';
import Modal from 'react-responsive-modal';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import { Confirm } from 'notiflix/build/notiflix-confirm-aio';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import { Report } from 'notiflix/build/notiflix-report-aio';
const { executeTransaction, EthereumContext, log, queryData, convertPriceToEth, queryEvents, convertPricefromEth } = require('react-solidity-web3');


export default function Xchange() {

    const [list, setlist] = useState([])
    const [open, setOpen] = useState(false);
    const { provider, XSynExchange, xdusd, XsynProtocol } = useContext(EthereumContext).ethereumContext;
    const [selectedlist, setselectedlist] = useState({})
    const [tokenCount, settokenCount] = useState(0)
    const [swapCount, setswapCount] = useState(0)

    const [balance, setbalance] = useState(0)
    const [selectedbalance, setselectedbalance] = useState(0)

    const [btndisabled, setbtndisabled] = useState(true)





    useEffect(() => {
        fetchSyntheticPrices()

    }, [])


    const onOpenModal = (value) => {
        setselectedlist(value)
        showXDUSDBalances()
        showBalances(value[1])
        setOpen(true);

    }
    const onCloseModal = () => {
        settokenCount(0)
        setswapCount(0)
        setbtndisabled(true)
        setOpen(false)
    };

    const showXDUSDBalances = async (symbol) => {
        let response1 = await queryData(XSynExchange, provider, 'showBalances', ["XDUSD"]);
        let bal = await convertPricefromEth(response1);
        setbalance(parseFloat(bal))
    }

    const showBalances = async (symbol) => {
        let response1 = await queryData(XSynExchange, provider, 'showBalances', [symbol]);
        let bal = await convertPricefromEth(response1);
        console.log("VALABLE BAL", bal)
        setselectedbalance(parseFloat(bal))
    }

    const fetchSyntheticPrices = async () => {
        let response1 = await queryData(XSynExchange, provider, 'fetchPrices', []);
        console.log("response1", response1)
        setlist(response1)
    }

    useEffect(() => {

        if (parseFloat(tokenCount) <= balance && parseFloat(tokenCount) > 0) {
            let currPrice = parseFloat(selectedlist[3]) / 10000000000000000;
            var tmpSwp = parseFloat(tokenCount) / currPrice;
            setswapCount(tmpSwp)
        } else {
            setswapCount(0)
            setbtndisabled(true)
        }
    }, [tokenCount])


    const onChangeCount = (e) => {

        if (parseFloat(e.target.value) && parseFloat(e.target.value) > balance) {
            Notify.failure("Low Blance");
            setbtndisabled(true)
            settokenCount(e.target.value)
            return;
        }
        setbtndisabled(false)
        settokenCount(e.target.value)
    }

    const onMaxClick = () => {
        setbtndisabled(false)
        settokenCount(balance.toString())
    }


    const approveTransfer = async (event) => {
        Loading.standard('Loading...', { messageColor: "#244b81", svgColor: "#244b81", svgSize: '50px', backgroundColor: 'rgba(255,255,255,1)', });

        Confirm.show(
            'Confirm',
            'Are you sure to approve transaction ?',
            'Yes',
            'No',
            async () => {
                let _stakeValue = await convertPriceToEth(tokenCount, "XDUSD");
                let response1 = await executeTransaction(xdusd, provider, 'approve', [XSynExchange.address, _stakeValue], 0);
                // Loading.remove()
                exchangeXDUSDwithSynths()
            },
            () => {
                Loading.remove()
            },
            {
            },
        );


    }

    const exchangeXDUSDwithSynths = async () => {
        // Loading.standard('Loading...', { messageColor: "#244b81", svgColor: "#244b81", svgSize: '50px', backgroundColor: 'rgba(255,255,255,1)', });
        let _amount = await convertPriceToEth(tokenCount, "XDUSD");
        let _preferredSynthAddress = selectedlist[4];
        let _woprefixSynth = selectedlist[2];
        let _wprefixSynth = selectedlist[1];
        console.log("stakevalue is", _amount);
        let response1 = await executeTransaction(XSynExchange, provider, 'exchangeXDUSDForSynths', [_amount, _preferredSynthAddress, _woprefixSynth, _wprefixSynth], 0);
        log("exchangeXDUSDwithSynths", "hash", response1.txHash);
        // let response2 = await queryEvents(XSynExchange, provider, 'updatedebt', response1.blockNumber);
        // console.log("response2", response2, response2[0], response2[1], response2[2], response2[3]);
        // let response3 = await executeTransaction(XsynProtocol, provider, 'updateDebtPoolSynth', [response2[0], response2[1], response2[2], response2[3]], 0);
        // log("updateDebtPoolSynth", "hash", response3.txHash);
        // let response4 = await executeTransaction(XsynProtocol, provider, 'updateDebtPoolXDUSD', [response2[0], response2[1]], 0);
        // log("updateDebtPoolXDUSD", "hash", response4.txHash);
        Loading.remove()
        Report.success(
            'Success',
            `Asset Purchase Successfull`,
            'Ok',
            () => {
                settokenCount(0)
                setswapCount(0)
                setbtndisabled(true)
                setOpen(false)
                showBalances(selectedlist[1])
            }
        );

    }



    return (
        <div className="nk-app-root">
            {/* main @s */}
            <div className="nk-main ">
                <Header />

                {/* sidebar @s */}
                <SidebarUser />
                {/* sidebar @e */}
                {/* wrap @s */}
                <div className="nk-wrap ">

                    {/* content @s */}
                    <div className="nk-content nk-content-fluid" style={{ paddingTop: 70 }}>
                        <div className="container-xl wide-lg">
                            <div className="nk-content-body">
                                <div className="nk-block">
                                    <div className="row g-4">
                                        <Modal open={open} onClose={onCloseModal} center>

                                            <div className="buysell-block">
                                                <form action="#" className="buysell-form">
                                                    <div className="buysell-field form-group">

                                                        <div className="form-label-group">
                                                            <label className="form-label">Swap Details</label>
                                                        </div>
                                                    </div>
                                                    <div className="buysell-field form-group" >
                                                        <div className="form-label-group">
                                                            <label className="form-label" htmlFor="buysell-amount">XDUSD</label>
                                                        </div>
                                                        <div className="form-control-group">
                                                            <input onChange={(e) => onChangeCount(e)} value={tokenCount} type="text" className="form-control form-control-lg form-control-number" id="buysell-amount" name="bs-amount" placeholder="100" />
                                                            <div className="form-dropdown" style={{ cursor: "pointer" }} onClick={onMaxClick}>
                                                                <div className="text">Max</div>

                                                            </div>
                                                        </div>
                                                        <div className="form-note-group">
                                                            <span className="buysell-min form-note-alt">Available Balance: {balance} XDUSD</span>
                                                        </div>
                                                    </div>
                                                    <div className="buysell-field form-group" style={{ marginBottom: 0 }}>
                                                        <div className="form-label-group">
                                                            <label className="form-label" htmlFor="buysell-amount">{selectedlist[1]}</label>
                                                        </div>
                                                        <div className="form-control-group">
                                                            <input type="text" value={swapCount} disabled className="form-control form-control-lg form-control-number" id="buysell-amount" name="bs-amount" placeholder="100" />

                                                        </div>
                                                        <div className="form-note-group">
                                                            <span className="buysell-min form-note-alt">Current Price: {parseFloat(selectedlist[3]) / 10000000000000000} XDUSD</span>
                                                            {/* <span class="buysell-rate form-note-alt text-danger">Asset Balance : {parseFloat(selectedbalance).toFixed(4)}</span> */}

                                                        </div>
                                                        <div className="form-note-group">
                                                            {/* <span className="buysell-min form-note-alt">Current Price: {parseFloat(selectedlist[3]) / 10000000000000000} XDUSD</span> */}
                                                            <span class="buysell-rate form-note-alt text-danger">Asset Balance : {parseFloat(selectedbalance).toFixed(4)}</span>

                                                        </div>

                                                    </div>
                                                </form>
                                            </div>


                                            <div className='text-center mt-3'>
                                                <button type="button" onClick={approveTransfer} disabled={btndisabled} className="btn btn-lg  btn-primary" >Swap</button>
                                            </div>

                                        </Modal>

                                        {
                                            list.map((res, index) => {

                                                // if (index < 3) {
                                                //     return;
                                                // }

                                                return <div className="col-sm-3 col-xxl-12">
                                                    <div className={`nk-order-ovwg-data sell`} style={{ backgroundColor: "#244b81" }}>
                                                        <div className="amount"><small className="currenct currency-usd" style={{ color: "#fff" }}>{res[1]}</small></div>
                                                        <div className="info" style={{ color: "#fff" }}>Current Price <strong style={{ color: "#fff" }}>{(parseFloat(res[3]) / 10000000000000000).toFixed(3)} </strong></div>
                                                        {/* <div className="info" style={{ color: "#fff" }}>Your Balance <strong style={{ color: "#fff" }}>0.00 </strong></div> */}
                                                        <div onClick={() => onOpenModal(res)} className="title" style={{ color: "#fff", cursor: 'pointer' }}>
                                                            <em className="icon ni ni-arrow-up-right" /> Swap
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* content @e */}
                    <Footer />
                </div>
            </div >
        </div >
    )
}
