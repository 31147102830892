import isEmpty from '../validation/is-empty';

import { SET_CURRENT_USER,REGISTER_USER,VERIFIED_USER } from "../actions/types";


const initialState = {
    isAuthenticated: false,
    user: {},
    registerDetail:{},
    verifyDetail:{}
}

export default function(state=initialState, action){
    switch(action.type){
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
        case REGISTER_USER:
        return {
            ...state,
            registerDetail: action.payload
        }
        case VERIFIED_USER:
        return {
            ...state,
            verifyDetail: action.payload
        }
        default:
            return state;
    }
}