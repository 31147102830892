import *  as types from './types';

export function getweb3Modal(web3modal) {
    return (dispatch) => {
        dispatch({
            type: types.GET_WEB3MODAL,
            item: web3modal
        });
    }
}

export function getSigner(signer) {
    return (dispatch) => {
        dispatch({
            type: types.GET_SIGNER,
            item: signer
        });
    }
}


export function getSelectedAccount(accountAddress) {
    return (dispatch) => {
        dispatch({
            type: types.GET_SELECTEDACCOUNT,
            item: accountAddress

        });
    }
}

export function setProvider(provider) {
    return (dispatch) => {
        dispatch({
            type: types.GET_PROVIDER,
            item: provider

        });
    }
}

export function setNftContract(provider) {
    return (dispatch) => {
        dispatch({
            type: types.GET_NFTCONTRACT,
            item: provider

        });
    }
}



export function setDisconnect() {
    return (dispatch) => {
        dispatch({
            type: types.GET_DISCONNECT,
        });
    }
}

export function clearWallet() {
    return (dispatch) => {
        dispatch({
            type: types.CLEAR_WALLET,
        });
    }
}