import '@rainbow-me/rainbowkit/styles.css';
import React from 'react';
import ReactDOM from 'react-dom/client'; // Import the new ReactDOM API for React 18
import './index.css';
import App from './App';

// Create the root container
const root = ReactDOM.createRoot(document.getElementById('pagerender'));

// Render the App component using the new createRoot method
root.render(
  <React.Fragment>
    <App />
  </React.Fragment>
);
