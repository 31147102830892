import React, { Component, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';
import { setCurrentUser, logoutUser } from './actions/authAction';
import PrivateRoute from './components/common/PrivateRoute';
import { Provider } from 'react-redux';
import store from './store';
import Web3 from "xdc3";
import ScrollToTop from './utils/ScrollToTop';
import './App.css';
import Landing from './components/layouts/Landing';
import Dashboard from './components/user/dashboard/Dashboard';
import Xchange from './components/user/xchange/Xchange';
import { Networks, XDCAPOTHEM } from './actions/constant';


import {
  getDefaultConfig,
  RainbowKitProvider,
  darkTheme,
  connectorsForWallets,

} from '@rainbow-me/rainbowkit';
import { injectedWallet, metaMaskWallet, rainbowWallet, walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';

import { createConfig, WagmiProvider } from 'wagmi';
import {
  mainnet,
  polygon,
  optimism,
  arbitrum,
  base,
} from 'wagmi/chains';
import {
  QueryClientProvider,
  QueryClient,
} from "@tanstack/react-query";

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [injectedWallet, metaMaskWallet, rainbowWallet, walletConnectWallet],
    },
  ],
  {
    appName: 'My RainbowKit App',
    projectId: '46bf01b0d37c02cae095eb76a4631cff',

  }
);
const config = createConfig({
  connectors,
  chains: [XDCAPOTHEM],
  ssr: false,
});

// const config = getDefaultConfig({
//   appName: 'My RainbowKit App',
//   projectId: '46bf01b0d37c02cae095eb76a4631cff',
//   chains: [XDCAPOTHEM],
//   ssr: false, // If your dApp uses server side rendering (SSR)
// });

const queryClient = new QueryClient();
const { connectWallet, createWeb3Provider, EthereumContext, createContractInstance, log } = require('react-solidity-web3');

export default function App() {
  const [ethereumContext, setethereumContext] = useState([]);

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider theme={darkTheme()}>
          <Provider store={store}>
            <EthereumContext.Provider value={{ ethereumContext, setethereumContext }}>
              <Router
                exact
                path="/"
              //forceRefresh={true}
              >
                <div class="nk-app-root">
                  <ScrollToTop />
                  <Routes>
                    <Route path="/" element={<Landing />} index exact />
                    <Route path="/" element={<PrivateRoute />}>
                      <Route path="/dashboard" exact element={<Dashboard />} />
                      <Route path="/xchange" exact element={<Xchange />} />
                    </Route>
                  </Routes>
                </div>
              </Router>
            </EthereumContext.Provider>
          </Provider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

