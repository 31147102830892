import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';
import axios from 'axios';
import { GET_ERRORS, SET_CURRENT_USER, REGISTER_USER, VERIFIED_USER } from './types';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
// import Web3Modal from 'web3modal';

//Login - Get User Token
export const loginAdmin = (userData) => dispatch => {
   axios.post('/api/admin/login', userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         if (token) {
            //Set Token to Localstorage
            localStorage.setItem('jwtToken', token);
            //Set Token to Header
            setAuthToken(token);
            //Decode Token to get user Data
            const decoded = jwt_decode(token);
            //set Current User
            dispatch(setCurrentUser(decoded));
         } else {
            dispatch({
               type: REGISTER_USER,
               payload: res.data
            })
         }
      })
      .catch(err => dispatch({
         type: GET_ERRORS,
         payload: err.response.data
      })

      );
};

export const verifyAdminOTP = (userData) => dispatch => {
   axios.post('/api/admin/verifyotp', userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         //Set Token to Localstorage
         localStorage.setItem('jwtToken', token);
         //Set Token to Header
         setAuthToken(token);
         //Decode Token to get user Data
         const decoded = jwt_decode(token);
         //set Current User
         dispatch(setCurrentUser(decoded));

      })
      .catch(err => dispatch({
         type: GET_ERRORS,
         payload: err.response.data
      })

      );
};
//Forgot password Admin
export const Forgetpassword = (userData) => dispatch => {
   axios.post('/api/admin/forget', userData)
      .then(res => {

      })
      .catch(err => dispatch({
         type: GET_ERRORS,
         payload: err.response.data
      })
      );
}

//Register User
export const registerUser = (userData) => dispatch => {
   axios.post('/api/user/register', userData)
      .then(res => {
         dispatch({
            type: REGISTER_USER,
            payload: res.data
         })
      })
      .catch(err => dispatch({
         type: GET_ERRORS,
         payload: err.response.data
      })

      );
};

export const verifyUser = (userData) => dispatch => {
   axios.post('/api/user/verify', userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         //Set Token to Localstorage
         localStorage.setItem('jwtToken', token);
         //Set Token to Header
         setAuthToken(token);
         //Decode Token to get user Data
         const decoded = jwt_decode(token);
         //set Current User
         dispatch(setCurrentUser(decoded));
      })
      .catch(err => dispatch({
         type: GET_ERRORS,
         payload: err.response.data
      })

      );
};


export const verifyUserOTP = (userData) => dispatch => {
   axios.post('/api/user/verifyotp', userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         if (token) {
            //Set Token to Localstorage
            localStorage.setItem('jwtToken', token);
            //Set Token to Header
            setAuthToken(token);
            //Decode Token to get user Data
            const decoded = jwt_decode(token);
            //set Current User
            dispatch(setCurrentUser(decoded));
         } else {
            dispatch({
               type: VERIFIED_USER,
               payload: res.data
            })
         }
      })
      .catch(err => dispatch({
         type: GET_ERRORS,
         payload: err.response.data
      })

      );
};

export const verifyUser2fa = (userData) => dispatch => {
   axios.post('/api/user/verify2falogin', userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         if (token) {
            //Set Token to Localstorage
            localStorage.setItem('jwtToken', token);
            //Set Token to Header
            setAuthToken(token);
            //Decode Token to get user Data
            const decoded = jwt_decode(token);
            //set Current User
            dispatch(setCurrentUser(decoded));
         }
      })
      .catch(err => dispatch({
         type: GET_ERRORS,
         payload: err.response.data
      })

      );
};

//Login - Get User Token
export const loginUser = (userData) => dispatch => {

   dispatch(setCurrentUser(userData));

   // axios.post('/api/user/login',userData)
   //      .then(res => {
   //         // Save to LocalStorage
   //         const {token} = res.data;
   //         if(token){
   //          //Set Token to Localstorage
   //          localStorage.setItem('jwtToken',token);
   //          //Set Token to Header
   //          setAuthToken(token);
   //          //Decode Token to get user Data
   //          const decoded = jwt_decode(token);
   //          //set Current User
   //          dispatch(setCurrentUser(decoded));
   //         }else{
   //          dispatch({
   //             type: REGISTER_USER,
   //             payload: res.data
   //           })
   //         }

   //      })
   //      .catch(err => dispatch({
   //         type: GET_ERRORS,
   //         payload: err.response.data
   //       })

   //     );
};


//Login - Get User Token
export const loginSupport = (userData) => dispatch => {
   axios.post('/api/support/login', userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         if (token) {
            //Set Token to Localstorage
            localStorage.setItem('jwtToken', token);
            //Set Token to Header
            setAuthToken(token);
            //Decode Token to get user Data
            const decoded = jwt_decode(token);
            //set Current User
            dispatch(setCurrentUser(decoded));
         } else {
            dispatch({
               type: REGISTER_USER,
               payload: res.data
            })
         }

      })
      .catch(err => dispatch({
         type: GET_ERRORS,
         payload: err.response.data
      })

      );
};

export const loginGoogle = (userData) => dispatch => {
   axios.post('/api/user/googlelogin', userData)
      .then(res => {
         // Save to LocalStorage
         const { token } = res.data;
         if (token) {
            //Set Token to Localstorage
            localStorage.setItem('jwtToken', token);
            //Set Token to Header
            setAuthToken(token);
            //Decode Token to get user Data
            const decoded = jwt_decode(token);
            //set Current User
            dispatch(setCurrentUser(decoded));
         } else {
            dispatch({
               type: VERIFIED_USER,
               payload: res.data
            })
         }
      })
      .catch(err => dispatch({
         type: GET_ERRORS,
         payload: err.response.data
      })

      );
};






//set Logged in user
export const setCurrentUser = (decoded) => {
   return {
      type: SET_CURRENT_USER,
      payload: decoded
   }
};

//Log out users
export const logoutUser = () => dispatch => {
   // Remove Token from localstorage

   //Remove Auth Header for future reference
   setAuthToken(false);
   //set Current user to {} which will set isAuthenticated to false
   dispatch(setCurrentUser({}));



}
