import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Web3 from "xdc3";

import { logoutUser } from '../../actions/authAction';
import { APPROVE_STAKE, MINIMUM_STAKE, PLUGINFARM_CONTRACT, PLUGIN_CONTRACT, PLUGIN_DEFI_CONTRACT } from '../../actions/constant';
import PluginContract from '../../contracts/abi.json';
import { useAccount } from 'wagmi';
import { ethers } from 'ethers';

const Header = ({ auth, web3, logoutUser }) => {
  const { address, isConnected, connector } = useAccount();  // Hook to check connection status

  const [showMenu, setShowMenu] = useState(false);
  const [tillStake, setTillStake] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);
  const [accountAddress, setAccountAddress] = useState('');
  const [accountBalance, setAccountBalance] = useState(0);
  const [pluginContract, setPluginContract] = useState(null);



  useEffect(() => {
    if (isConnected && connector && Object.keys(connector).length > 0) {
      loadWeb3()
    }
  }, [isConnected, connector])


  // Load Web3 instance
  const loadWeb3 = useCallback(async () => {

    if (connector && typeof connector.getProvider === 'function') {

      const providerInit = await connector.getProvider()
      // console.log("providerInit", providerInit)
      if (!providerInit) {
        return;
      }
      const provider = new ethers.providers.Web3Provider(providerInit);
      const signer = await provider.getSigner(); // Get the signer (connected account)
      const accounts = await provider.listAccounts(); // Fetch connected accounts
      const accountAddress = accounts[0];
      setAccountAddress(accountAddress);
      // Get account balance
      let balance = await provider.getBalance(accountAddress);
      balance = ethers.utils.formatEther(balance);
      setAccountBalance(parseFloat(balance).toFixed(4));

      // Instantiate contract
      const plugin = new ethers.Contract(PLUGIN_CONTRACT, PluginContract, signer);
      setPluginContract(plugin);

      // Get token balance
      const tokenBalance = await plugin.balanceOf(accountAddress);
      const formattedTokenBalance = ethers.utils.formatEther(tokenBalance);
      setTokenBalance(parseFloat(formattedTokenBalance).toFixed(4));
    }

  }, []);





  const navlinks = [
    { name: 'Dashboard', link: '/dashboard', icon: <em className="icon ni ni-dashboard" /> },
    { name: 'Xchange', link: '/xchange', icon: <em className="icon ni ni-list-index" /> },
  ];

  const pathname = window.location.pathname;

  return (
    <div className="header-main header-main-s1 is-sticky d-xl-none">
      <div className="container header-container">
        <div className="header-wrap">
          <div className="header-logo">
            <a href="/" className="logo-link">
              <img
                className="logo-light logo-img"
                src="./images/logo-new-2.png"
                srcSet="./images/logo-new-2.png 2x"
                style={{ width: '50%' }}
                alt="logo"
              />
              <img
                className="logo-dark logo-img"
                src="./images/logo-new-2.png"
                srcSet="./images/logo-new-2.png 2x"
                style={{ width: '50%' }}
                alt="logo-dark"
              />
            </a>
          </div>
          <div className="header-toggle">
            <button
              className={`menu-toggler ${showMenu ? 'active' : ''}`}
              data-target="mainNav"
              onClick={() => setShowMenu(!showMenu)}
            >
              {!showMenu && <em className="menu-on icon ni ni-menu text-dark" />}
              {showMenu && <em className="menu-off icon ni ni-cross text-dark" />}
            </button>
          </div>

          <nav
            className={`header-menu ${showMenu ? 'mobile-menu active' : ''}`}
            data-content="mainNav"
          >
            <div className="nk-sidebar-content">
              <div className="nk-sidebar-widget">
                <div className="user-account-info between-center">
                  <div className="user-account-main">
                    <h6 className="overline-title-alt">Available Balance</h6>
                    <div className="user-balance">
                      {tokenBalance} <small className="currency currency-btc">PLI</small>
                    </div>
                    <div className="user-balance-alt">
                      {accountBalance} <span className="currency currency-btc">XDC</span>
                    </div>
                  </div>
                </div>
                <div className="user-account-actions">
                  <ul className="g-3">
                    <li>
                      <a
                        target="_blank"
                        href="https://faucet.apothem.network"
                        className="btn btn-lg btn-primary"
                      >
                        <span>Get XDC</span>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        href="https://faucet.goplugin.co"
                        className="btn btn-lg btn-warning"
                      >
                        <span>Get PLI</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="nk-sidebar-menu">
                <ul className="nk-menu">
                  <li className="nk-menu-heading">
                    <h6 className="overline-title">Menu</h6>
                  </li>
                  {navlinks.map((result) => (
                    <li
                      key={result.link}
                      className={`nk-menu-item ${pathname === result.link ? 'active current-page' : ''
                        }`}
                    >
                      <Link to={result.link} className="nk-menu-link">
                        <span className="nk-menu-icon">{result.icon}</span>
                        <span className="nk-menu-text">{result.name}</span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  auth: PropTypes.object.isRequired,
  web3: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  web3: state.web3,
});

export default connect(mapStateToProps, { logoutUser })(Header);
