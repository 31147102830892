export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const VERIFIED_USER = 'VERIFIED_USER';


//WEB3
export const GET_WEB3MODAL = 'GET_WEB3MODAL';
export const GET_SIGNER = 'GET_SIGNER';
export const GET_SELECTEDACCOUNT = 'GET_SELECTEDACCOUNT';
export const GET_PROVIDER = 'GET_PROVIDER';
export const GET_NFTCONTRACT = 'GET_NFTCONTRACT';
export const CLEAR_WALLET = 'CLEAR_WALLET';
export const GET_DISCONNECT = 'GET_DISCONNECT';

