import * as types from '../actions/types';


const initialState = {
	web3modal: null,
	selectedAccount: null,
	walletConnected: false,
	provider: null,
	nftcontract: null,
	signer: null
}
export default function (state = initialState, action) {
	switch (action.type) {
		case types.GET_WEB3MODAL:
			return {
				...state,
				web3modal: action.item
			}
		case types.GET_SIGNER:
			return {
				...state,
				signer: action.item
			}

		case types.GET_SELECTEDACCOUNT:
			return {
				...state,
				selectedAccount: action.item,
				walletConnected: true
			}
		case types.GET_PROVIDER:
			return {
				...state,
				provider: action.item
			}
		case types.GET_NFTCONTRACT:
			return {
				...state,
				nftcontract: action.item
			}

		case types.GET_DISCONNECT:
			return {
				...state,
				web3modal: null,
				selectedAccount: null,
				web3: null,
				walletConnected: false,
				provider: null,
				nftcontract: null,

			}
		case types.CLEAR_WALLET:
			return {
				...state,
				selectedAccount: null,
				walletConnected: false,
			}
		default:
			return state;
	}
}