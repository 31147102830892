
export const IMAGE_URL = 'https://oracles.goplugin.co';
export const MINIMUM_STAKE = 2000;
export const APPROVE_STAKE = 1000000;

//APOTHEM
export const EXPLORER_URL = "https://explorer.apothem.network/tx";
export const EXPLORER_WALLETURL = "https://explorer.apothem.network/address";
export const PLUGIN_CONTRACT = "0x33f4212b027e22af7e6ba21fc572843c0d701cd1";
export const PLUGIN_DEFI_CONTRACT = "0xe2b78d27f0c07f7f7630971b297bbf0d121b92a7";
export const PLUGIN_TEMP_CONTRACT = "0x48191fD62919bd8817b5efD2FE59194d0Ff485F5";
export const WEATHER_DEFI_CONTRACT = "0xebA5880252f5Cad875099d87809d99a916891c7F";
export const WEATHER_REWARD_CONTRACT = "0x8e00Dd1e979BB6b0dFed6Dc1B5E5A7c801c36e32";
export const PLUGINFARM_CONTRACT = "0xa947272574a4D92c40b2b0D031601A34Cd9547EE";
export const PLUGINFARM_REWARD_CONTRACT = "0x38cCE2558c21C91e6D10981670Fa18fe587e8495";
export const CHAIN_ID = "51";
export const NETWORK_NAME = "APOTHEM";






//MAINNET
// export const EXPLORER_URL = "https://explorer.xinfin.network/txs";
// export const EXPLORER_WALLETURL = "https://explorer.xinfin.network/address";
// export const PLUGIN_CONTRACT = "0xff7412ea7c8445c46a8254dfb557ac1e48094391";
// export const PLUGIN_DEFI_CONTRACT = "0xdd91318eb71f10d50dd1cdd12689f15f95dc8a9e";
// export const PLUGIN_TEMP_CONTRACT = "0xe6736e55Dd252f9b1ad584ECb958f6C8563fF21B";
// export const WEATHER_DEFI_CONTRACT = "0x06892B4c9f612312E0981f0CA95CF06890708F74";
// export const WEATHER_REWARD_CONTRACT = "0x7057eB16BA274ccd3C657A31cb8E6c4630de37C3";
// export const PLUGINFARM_CONTRACT = "0xf3a92D46D804202e3d6d43F253BBD374546f4735";
// export const PLUGINFARM_REWARD_CONTRACT = "0xF0B4a15D7755d67a0cd641766Fe065b4ab470e6F";
// export const CHAIN_ID = "50";
// export const NETWORK_NAME = "MAINNET";



export const UCASE = ([first, ...rest], locale = navigator.language) =>
  first.toLocaleUpperCase(locale) + rest.join('')

export const Networks = [
  {
    chainId: 50,
    name: 'XDC Mainnet',
    currency: 'XDC',
    explorerUrl: 'https://explorer.xinfin.network',
    rpcUrl: 'https://xdcfnode1.goplugin.co',
    icon: "https://s2.coinmarketcap.com/static/img/coins/64x64/2634.png"
  },
  {
    chainId: 51,
    name: 'XDC Apothem',
    currency: 'XDC',
    explorerUrl: 'https://explorer.apothem.network',
    rpcUrl: 'https://erpc.apothem.network'
  },
  // {
  //     chainId: 137,
  //     name: 'Polygon Mainnet',
  //     currency: 'MATIC',
  //     explorerUrl: 'https://polygonscan.com',
  //     rpcUrl: 'https://polygon.llamarpc.com'
  // },
  // {
  //     chainId: 80001,
  //     name: 'Polygon Mumbai',
  //     currency: 'MATIC',
  //     explorerUrl: 'https://mumbai.polygonscan.com',
  //     rpcUrl: 'https://polygon-mumbai.g.alchemy.com/v2/zG7YTFY-C4PqNM7tqTNksJ-4Ab3p_kJc'
  // },
]

export const XDCAPOTHEM = {
  id: 51,
  name: 'XDC Apothem',
  iconUrl: 'https://s2.coinmarketcap.com/static/img/coins/64x64/2634.png',
  iconBackground: '#fff',
  nativeCurrency: { name: 'Apothem', symbol: 'XDC', decimals: 18 },
  rpcUrls: {
    default: { http: ['https://erpc.apothem.network'] },
  },
  blockExplorers: {
    default: { name: 'Apothem', url: 'https://explorer.apothem.network' },
  },
}