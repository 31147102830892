import React from 'react'

export default function Footer() {
  return (
    <div className="nk-footer">
      <div className="container-fluid">
        <div className="nk-footer-wrap">
          <div className="nk-footer-copyright"> © 2024 SynthX Lab.
          </div>
        </div>
      </div>
    </div>
  )
}
