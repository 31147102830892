import React from 'react'
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute = (props) => {

    const auth = useSelector(state => state.auth);
    return auth.isAuthenticated === true && auth.user.userType === "user" ? <Outlet /> : <Navigate to="/" />
};



export default PrivateRoute;
