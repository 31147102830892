import { combineReducers } from 'redux';
import authReducer from './authReducer';
import errorReducer from './errorReducer';
import web3Reducer from './web3Reducer';


















export default combineReducers({
    auth: authReducer,
    errors: errorReducer,
    web3: web3Reducer
});
