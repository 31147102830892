import React, { Component, useEffect, useState, useContext } from 'react'
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Link, useNavigate } from 'react-router-dom'
import { loginUser } from '../../actions/authAction'
import { Report } from 'notiflix/build/notiflix-report-aio';
// import Web3Modal from 'web3modal';
// import WalletConnect from "@walletconnect/web3-provider";
import { ethers } from 'ethers';
// import { getXdcModal } from 'xdcpay-web3modal'
import { CHAIN_ID, NETWORK_NAME } from '../../actions/constant';
import { getweb3Modal, getSigner, getSelectedAccount, setProvider, setNftContract } from '../../actions/web3Action';
import { useSelector, useDispatch } from 'react-redux'

//BKC IMPORTS
import ADDRESSABI from '../../artifacts/contracts/AddressResolver.sol/AddressResolver.json';

import addresses from '../../output.json';
import XSYNABI from '../../artifacts/contracts/XSynProtocol.sol/XSynProtocol.json';
import XSYNEXCHANGE from '../../artifacts/contracts/XSynExchange.sol/XSynExchange.json';
import XRC20 from '../../xrc20/xrc20.json';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { useAccount, useDisconnect, usePro } from 'wagmi';
import appendScript from '../../utils/appendScript';
import removeScript from '../../utils/removeScript';


const { connectWallet, createWeb3Provider, EthereumContext, createContractInstance, log } = require('react-solidity-web3');

const { ADDRESSRESOLVER: addressresolver, XSynProtocol: xsynaddress, XSynExchange: xsynexchangeaddress, XDUSD, pliaddress } = addresses
const { abi: xsynabi } = XSYNABI
const { abi: xsynexchangeabi } = XSYNEXCHANGE
const { abi: xrc20abi } = XRC20
const { abi: addressresolverabi } = ADDRESSABI






// const web3Modal = new Web3Modal({
//   cacheProvider: true,
//   disableInjectedProvider: false,
//   providerOptions: {
//     walletconnect: {
//       package: WalletConnect, // required
//       options: {
//         infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
//         rpc: {
//           50: "https://rpc.xinfin.network",
//           51: "https://rpc.apothem.network"
//         },
//       }
//     },
//     // 'custom-xdc': getXdcModal,
//   }
// });



export default function Landing({ history }) {
  const [accountAddress, setaccountAddress] = useState()
  const navigate = useNavigate()

  const { ethereumContext, setethereumContext } = useContext(EthereumContext)
  const dispatch = useDispatch();
  const { isAuthenticated, user, loading } = useSelector(state => state.auth)

  const [showmenu, setshowmenu] = useState(false)


  const { address, isConnected, connector } = useAccount();  // Hook to check connection status
  const { disconnect } = useDisconnect();
  // Hook to disconnect
  useEffect(() => {
    if (isConnected && connector && Object.keys(connector).length > 0) {
      onConnect()
    }
  }, [isConnected, connector])

  useEffect(() => {
    if (isAuthenticated && Object.keys(user).length > 0) {
      navigate("/dashboard")
    }
  }, [user, isAuthenticated])


  const onConnect = async () => {

    if (connector && typeof connector.getProvider === 'function') {

      const providerInit = await connector.getProvider()
      // console.log("providerInit", providerInit)
      if (!providerInit) {
        return;
      }
      const provider = new ethers.providers.Web3Provider(providerInit);
      const { chainId } = await provider.getNetwork();
      console.log("chainId", chainId)
      if (chainId != CHAIN_ID) {
        Report.warning(
          'Network Mismatch',
          `Please Switch to ${NETWORK_NAME}`,
          'OK',
        );
        return;
      }

      //SEND XDC
      const signer = provider.getSigner();
      const address = await signer.getAddress();

      dispatch(setProvider(provider))
      dispatch(getSelectedAccount(address))
      dispatch(getSigner(signer))

      //BKC IMPORT
      const AddresssResolver = await createContractInstance(addressresolver, addressresolverabi, provider);
      const XsynProtocol = await createContractInstance(xsynaddress, xsynabi, provider);
      const XSynExchange = await createContractInstance(xsynexchangeaddress, xsynexchangeabi, provider);
      const pli = await createContractInstance(pliaddress, xrc20abi, provider);
      const xdusd = await createContractInstance(XDUSD, xrc20abi, provider);

      const account = await signer.getAddress();
      setethereumContext({ provider, AddresssResolver, account, XsynProtocol, XSynExchange, pli, xdusd })


      dispatch(loginUser({ walletAddress: address, userType: "user" }))
    }

  }


  // useEffect(() => {
  //   appendScript("./assets/js/bundle.js?ver=3.0.0")
  //   appendScript("./assets/js/scripts.js?ver=3.0.0")
  //   appendScript("./assets/js/charts/chart-crypto.js?ver=3.0.0")

  //   return () => {
  //     removeScript("./assets/js/bundle.js?ver=3.0.0")
  //     removeScript("./assets/js/scripts.js?ver=3.0.0")
  //     removeScript("./assets/js/charts/chart-crypto.js?ver=3.0.0")
  //   }

  // }, [])


  return (
    <div>
      <>
        <header className="header header-32 has-header-main-s1 bg-dark" id="home">
          <div className="header-main header-main-s1 is-sticky is-transparent on-dark">
            <div className="container header-container">
              <div className="header-wrap">
                <div className="header-logo">
                  <a href="/" className="logo-link">
                    <img
                      className="logo-light logo-img"
                      src="./images/logo-white.png"
                      srcSet="./images/logo-white.png 2x"
                      alt="logo"
                    />
                    <img
                      className="logo-dark logo-img"
                      src="./images/logo-white.png"
                      srcSet="./images/logo-white.png 2x"
                      alt="logo-dark"
                    />
                  </a>
                </div>
                <div className="header-toggle">
                  <button className={`menu-toggler ${showmenu ? "active" : null}`} data-target="mainNav" onClick={() => setshowmenu(!showmenu)}>
                    {!showmenu && <em className="menu-on icon ni ni-menu" />}
                    {showmenu && <em className="menu-off icon ni ni-cross" />}
                  </button>
                </div>
                {/* .header-nav-toggle */}
                <nav className={`header-menu ${showmenu ? "mobile-menu active" : null} `} data-content="mainNav">
                  <ul className="menu-list ms-lg-auto">
                    {showmenu && <>
                      <li className="menu-item">
                        <a
                          href="https://knowledge.goplugin.co/knowledgebase/categories/synthx-labs" class="menu-link nav-link active"
                          target="_blank"
                        >FAQ</a>
                      </li>
                      <li className="menu-item">
                        <a
                          href="https://feeds.goplugin.co" class="menu-link nav-link active"
                          target="_blank"
                        >Plugin Feeds</a>
                      </li>
                    </>}
                  </ul>
                  {!showmenu && <ul className="menu-btns">

                    <li>
                      <a
                        href="https://knowledge.goplugin.co/knowledgebase/categories/synthx-labs"
                        target="_blank"
                        className="btn btn-outline-primary btn-lg btn mx-2 text-white"
                      >
                        FAQ
                      </a>
                      <a
                        href="https://feeds.goplugin.co"
                        target="_blank"
                        className="btn btn-primary btn-lg"
                      >
                        Plugin Feeds
                      </a>
                    </li>
                  </ul>}
                </nav>
                {/* .nk-nav-menu */}
              </div>
              {/* .header-warp*/}
            </div>
            {/* .container*/}
          </div>
          {/* .header-main*/}
          <div className="header-content py-6 is-dark mt-lg-n1 mt-n3">
            <div className="container">
              <div className="row flex-row-reverse justify-content-center text-center g-gs">
                <div className="col-lg-8 col-md-8">
                  <div className="header-caption">
                    <h1 className="header-title">
                      A Synthetix Derivative Protocol
                    </h1>
                    <p>
                      SynthX Lab enables users to purchase a wide range of synthetic assets using XDUSD, a stablecoin backed by XDC and PLI tokens. Users can seamlessly swap synthetic assets via Contract-to-Contract (CTC) transactions, which eliminates slippage and liquidity concerns. The platform is currently deployed on the Apothem Network and is open for community trials.
                    </p>
                    <p>
                      Powered by Plugin – a decentralized oracle network – SynthX Lab ensures accurate price feeds for all supported assets.
                    </p>
                    <ul className="header-action btns-inline py-3">
                      <li>
                        {/* {!isAuthenticated ? <button type="button" onClick={() => onConnect()} className="btn btn-lg btn-primary">Connect Wallet</button> : <button type="button" onClick={() => history.push("/dashboard")} className="btn btn-lg btn-primary ">Continue to Dashboard</button>} */}
                        {!isAuthenticated ? <ConnectButton accountStatus="avatar" /> : <button type="button" onClick={() => navigate("/dashboard")} className="btn btn-lg btn-primary ">Continue to Dashboard</button>}
                      </li>
                      <li>
                        <a href="https://plugin.global/contact-us.html" className="btn btn-danger btn-lg">
                          <span>Contact Us</span>
                        </a>
                      </li>
                    </ul>
                    {/* .header-action */}
                    {/* <ul className="header-icon list-inline pt-1">
                      <li>
                        <img
                          className="h-20px"
                          src="./images/icon/libs/javascript.png"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="h-20px"
                          src="./images/icon/libs/sass.png"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="h-20px"
                          src="./images/icon/libs/gulp.png"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="h-20px"
                          src="./images/icon/libs/bootstrap.png"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="h-20px"
                          src="./images/icon/libs/html5.png"
                          alt=""
                        />
                      </li>
                      <li>
                        <img
                          className="h-20px"
                          src="./images/icon/libs/css3.png"
                          alt=""
                        />
                      </li>
                    </ul> */}
                  </div>
                  {/* .header-caption */}
                </div>
                {/* .col */}
              </div>
              {/* .row */}
            </div>
            {/* .container */}
          </div>
          {/* .header-content */}
        </header>
        {/* .header */}
      </>

    </div>
  )

}



